import React, { useState, useEffect, useRef } from 'react';
import sendMessageToIframe from '../helpers/santander';

function Santander() {
  const [token, setToken] = useState('');
  const [open, setOpen] = useState(false);
  const [iframeWidth, setIframeWidth] = useState('1100px');
  const [iframeHeight, setIframeHeight] = useState('574px');
  const [isWidgetReady, setWidgetReady] = useState(false);
  const pendingLogin = useRef(null);

  const handleMessage = (event) => {
    // Validate message origin for security
    const expectedOrigin = process.env.REACT_APP_ENROLLMENT_WIDGET_URI.replace(/\/$/, '');
    if (event.origin !== expectedOrigin) {
      console.warn('Received message from unauthorized origin:', event.origin);
      return;
    }

    if (event.data?.type === 'WIDGET_READY') {
      setWidgetReady(true);
      
      if (pendingLogin.current) {
        sendMessageToIframe(pendingLogin.current);
        pendingLogin.current = null;
        setWidgetReady(false);
      }
    }
  };

  // Set up message listener
  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const handleOnLoad = () => {
    const loginMessage = {
      type: 'LOGIN',
      data: { token },
    };

    if (isWidgetReady) {
      sendMessageToIframe(loginMessage);
      setWidgetReady(false);
    } else {
      pendingLogin.current = loginMessage;
    }
  };

  // UI event handlers
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    sendMessageToIframe({ type: 'LOGOUT' });
    setOpen(false);
  };
  const handleChange = (event) => setToken(event.target.value);
  const handleWidthChange = (event) => setIframeWidth(event.target.value);
  const handleHeightChange = (event) => setIframeHeight(event.target.value);

  return (
    <div className="santander">
      <h3>Enrollment Widget</h3>
      <div>
        <p>Token:</p>
        <input type="text" value={token} onChange={handleChange} />
      </div>
      <div className='input-row'>
      <div className='input-group'>
        <p>Width: </p>
        <input type="text" value={iframeWidth} onChange={handleWidthChange} />
      </div>
      <div className='input-group'>
        <p>Height: </p>
        <input type="text" value={iframeHeight} onChange={handleHeightChange} />
      </div>
      </div>

      <div>
        <button onClick={handleOpen} disabled={!token}>
          Abrir iframe
        </button>
        <button onClick={handleClose} disabled={!open}>
          Cerrar iframe
        </button>
      </div>
      {open && (
        <iframe
          id="enrollment-iframe"
          src={process.env.REACT_APP_ENROLLMENT_WIDGET_URI}
          title="enrollment-widget"
          width={iframeWidth}
          height={iframeHeight}
          onLoad={handleOnLoad}
        ></iframe>
      )}
    </div>
  );
}

export default Santander;
